<section *ngIf="productionOrders$ | async as productionOrders" class="documents page-padding">
  <div class="documents__sidebar">
    <app-scrollable-sidebar
      [itemList]="productionOrders"
      [scrollTo]="initialProductionOrderIndex"
      (searchBarValueChanged)="searchBarValueChanged($event)"
    >
      <app-order-sidebar-item
        *appListItem="let item"
        [item]="item"
        [isItemActive]="isItemActive(item)"
        (itemSelected)="onOrderItemSelect($event)"
      ></app-order-sidebar-item>
    </app-scrollable-sidebar>
  </div>
  <div class="documents__menu">
    <p-tabMenu [model]="documentTabs" [activeItem]="activeTab">
      <ng-template pTemplate="item" let-item let-i="index">
        <div class="ui-menuitem-text">
          {{ item.label | translate }}
        </div>
      </ng-template>
    </p-tabMenu>
    <div class="menu__tab">
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
