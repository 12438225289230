<section class="qa-log page-padding">
  <p-tabMenu [model]="qaLogTabs" [activeItem]="activeTab">
    <ng-template pTemplate="item" let-item>
      <div class="ui-menuitem-text">
        {{ item.label | translate }}
      </div>
    </ng-template>
  </p-tabMenu>

  <div class="qa-log__content">
    <router-outlet></router-outlet>
  </div>
</section>
