<section class="registration-table">
  <p-table [value]="loggedInData">
    <ng-template pTemplate="header">
      <tr>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.NAME' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.ROLE' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.LOGINTIME' | translate }}</th>
        <th class="registration-table__default-cell">{{ 'EMPLOYEEREGISTRATION.ID' | translate }}</th>
        <th class="registration-table__default-cell">{{ 'EMPLOYEEREGISTRATION.REGISTRATION_PURPOSE' | translate }}</th>
        <th class="registration-table__default-cell">{{ 'EMPLOYEEREGISTRATION.NOTE' | translate }}</th>
        <th class="registration-table__last-cell"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="registration-table__text-cell">{{ rowData.name }}</td>
        <td>
          <lib-dropdown
            [ngModel]="rowData.role"
            [showDefaultValue]="true"
            [defaultValueLabel]="'DROPDOWN_DEFAULT_LABEL' | translate"
            [options]="roles"
            [inlineStyle]="{ width: '200px' }"
            (ngModelChange)="onRoleChange($event, rowData)"
          ></lib-dropdown>
        </td>
        <td class="registration-table__text-cell">{{ rowData.loginTime | utcToLocal | date: 'dd.MM.YYYY HH:mm' }}</td>
        <td class="registration-table__text-cell">
          {{ rowData.externalEmployeeId }}
        </td>
        <td class="registration-table__text-cell">
          <span *ngIf="rowData.registrationPurpose; else noData">{{ rowData.registrationPurpose }}</span>
        </td>
        <td class="registration-table__text-cell">
          <input
            (blur)="onLoggedInEmpoyeeBlur($event.srcElement.value, rowData)"
            [ngModel]="rowData.note"
            id="print-label-input"
            pInputText
            type="text"
            [maxLength]="250"
          />
        </td>
        <td>
          <lib-button
            (clicked)="onLogOutClick(rowData)"
            [label]="'EMPLOYEEREGISTRATION.LOGOUT' | translate"
            [typeClass]="'button--primary'"
            [sizeClass]="'button--small'"
          ></lib-button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template #noData>
    <span>-</span>
  </ng-template>
</section>
