import { Component, OnDestroy, OnInit } from '@angular/core';
import { MountingService } from '@app/modules/mounting/services';
import { nav } from '@app/shared/utils';
import { MenuItem } from 'primeng/api';
import { Router, RouterEvent } from '@angular/router';
import { UiStateService } from '@app/core/global-state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mounting',
  templateUrl: './mounting.component.html',
  styleUrls: ['./mounting.component.scss']
})
export class MountingComponent implements OnInit, OnDestroy {
  public mountingTabs: MenuItem[];
  private subscriptions = new Subscription();

  constructor(private mountingService: MountingService, private router: Router, private uiStateService: UiStateService) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.mountingService.getActiveOrderId().subscribe((activeOrderId) => {
        this.initMountingTabs(activeOrderId);
      })
    );
    this.subscriptions.add(
      this.router.events.subscribe((event: RouterEvent) => {
        this.setRouteState(event);
      })
    );
  }

  private setRouteState(event: RouterEvent): void {
    const mountingTab = this.mountingTabs.find((x) => x.routerLink === event.url);

    if (mountingTab) {
      this.uiStateService.setMountingStateSelectedRoute(event.url);
    }
  }

  private initMountingTabs(activeOrderId: number): void {
    const isTabDisabled = !activeOrderId;

    this.mountingTabs = [
      {
        label: 'MOUNTING.OVERVIEW',
        routerLink: nav.routes.mountingOverview
      },
      {
        label: 'MOUNTING.PRIMARY',
        disabled: isTabDisabled,
        routerLink: nav.routes.mountingPrimary
      },
      {
        label: 'MOUNTING.SECONDARY',
        disabled: isTabDisabled,
        routerLink: nav.routes.mountingSecondary
      },
      {
        label: 'MOUNTING.TRACE',
        disabled: isTabDisabled,
        routerLink: nav.routes.mountingTrace
      }
    ];
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getActiveTab(): MenuItem {
    return this.mountingTabs.find((tab) => this.router.isActive(tab.routerLink, true));
  }
}
