import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ListValue } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { OutputChangeQuantity, OutputFinish } from '@app/modules/run-phase/models';
import { Quantity, ActiveProductionOrder, PeriodicQaCheck } from 'chronos-core-client';
import { OutputUnitListService } from '@app/modules/run-phase/services/output-unit-list';
import { OutputQuantityChangeComponent } from '@app/modules/run-phase/containers/output/components/output-quantity-change';
import { Subscription } from 'rxjs';
import { QaCheckService } from '@app/core/services/qa-check/qa-check.service';
import { PeriodicQaCheckModalComponent } from '../periodic-qa-check-modal/periodic-qa-check-modal.component';
@Component({
  selector: 'app-output-unit-list',
  templateUrl: './output-unit-list.component.html',
  styleUrls: ['./output-unit-list.component.scss']
})
export class OutputUnitListComponent implements OnInit {
  @Input() public reasonOptions: ListValue[];
  @Input() public grossQuantity: Quantity;
  @Input() public activeProductionOrder: ActiveProductionOrder;
  @Output() public finishClicked = new EventEmitter<OutputFinish>();
  @Output() public openPeriodicQaCheckClicked = new EventEmitter<void>();

  public outputUnit$ = this.outputUnitListService.getOutputUnitWithNotifications();
  private subscriptions = new Subscription();
  private activeProductionOrderId;
  public isManualCheckAvailable;

  constructor(
    private outputUnitListService: OutputUnitListService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private qaCheckService: QaCheckService
  ) {}

  public ngOnInit() {
    this.activeProductionOrderId = this.activeProductionOrder.productionOrderId;
    this.subscriptions.add(
      this.qaCheckService.getManualProductionQaChecks(this.activeProductionOrderId).subscribe((data) => {
        this.manualProductionQAChecks(data);
      })
    );
  }

  public changeOutputUnitQuantity(changeQuantityEvent: OutputChangeQuantity): void {
    this.dialogService.open(OutputQuantityChangeComponent, {
      header: this.translateService.instant('OUTPUT_UNIT.CHANGE_OUTPUT_UNIT_QUANTITY'),
      data: {
        changeQuantity: changeQuantityEvent,
        isUnit: true
      }
    });
  }

  public onFinishClicked(finishEvent: OutputFinish): void {
    this.finishClicked.emit(finishEvent);
  }

  private manualProductionQAChecks(setupQaChecks: PeriodicQaCheck): void {
    this.isManualCheckAvailable = setupQaChecks.productionQaChecks.length > 0 ?? false;
  }

  public openPeriodicQACheckDialog(): void {
    this.dialogService.open(PeriodicQaCheckModalComponent, {
      header: this.translateService.instant('RUN_PHASE.PERIODIC_QA_CHECK'),
      data: {
        productionOrderId: this.activeProductionOrderId
      }
    });
  }
}
