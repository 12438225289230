<section class="operation-support page-padding">
  <div class="operation-support__sidebar" style="display: none">
    <p-tabMenu [model]="operationSupportTabs" [activeItem]="activeTab">
      <ng-template pTemplate="item" let-item let-i="index">
        <div class="ui-menuitem-text">
          {{ item.label | translate }}
        </div>
      </ng-template>
    </p-tabMenu>
  </div>
  <div class="operation-support__menu">
    <router-outlet></router-outlet>
  </div>
</section>
