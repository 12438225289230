import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AvailableEmployee } from 'chronos-core-client';

@Component({
  selector: 'app-employee-table',
  templateUrl: './employee-table.component.html',
  styleUrls: ['./employee-table.component.scss']
})
export class EmployeeTableComponent implements OnInit {
  constructor() {}
  @Input() public availableEmployees: AvailableEmployee[];
  @Output() public logIn = new EventEmitter<AvailableEmployee>();

  public ngOnInit(): void {}

  public onLogInClick(availableEmployee: AvailableEmployee) {
    this.logIn.emit(availableEmployee);
  }
}
