<section class="material-table">
  <p-treeTable
    [rowTrackBy]="trackByFunction"
    [value]="secondaryMaterialData"
    (onNodeExpand)="onNodeExpand($event)"
    (onNodeCollapse)="onNodeCollapse($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="material-table__toggle-cell">
          <i
            class="material-table__expand-collapse-icon"
            [ngClass]="tableExpanded ? 'icon-collapse-all' : 'icon-expand-all'"
            (click)="expandCollapseTable()"
          ></i>
        </th>
        <th class="material-table__tiny-cell"></th>
        <th class="material-table__text-cell">{{ 'MOUNTING.ARTICLE' | translate }}</th>
        <th [colSpan]="2" class="material-table__text-cell">{{ 'MOUNTING.NAME' | translate }}</th>
        <th class="material-table__text-cell">{{ 'MOUNTING.QUANTITY' | translate }}</th>
        <th *ngIf="showMountedCol" class="material-table__default-cell">{{ 'MOUNTING.MOUNTED' | translate }}</th>
        <th class="material-table__default-cell">{{ 'MOUNTING.CONSUMED' | translate }}</th>
        <th class="material-table__default-cell">{{ 'MOUNTING.OPEN' | translate }}</th>
        <th class="material-table__default-cell">{{ 'MOUNTING.ON_HAND' | translate }}</th>
        <th *ngIf="buttonTemplate" [style]="buttonTemplateStyle"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr
        [ngClass]="{
          'second-level-row': rowData.isBatch,
          'first-level-row': !rowData.isBatch,
          'material-table__dummy-row': rowData.isVirtualContainer
        }"
      >
        <td class="material-table__toggle-cell">
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        </td>
        <ng-container *ngIf="!rowData.isBatch; else batchInfo">
          <td class="material-table__tiny-cell">
            <div *ngIf="rowData.showLayerNumber">{{ rowData.layerNumber }}</div>
          </td>
          <td class="material-table__text-cell">
            <div>{{ rowData.article.externalArticleId }}</div>
            <div>{{ rowData.article.externalConfigurationId }}</div>
          </td>
          <td [colSpan]="2" class="material-table__text-cell">{{ rowData.article.articleName }}</td>
          <td class="material-table__text-cell">
            <div *ngIf="rowData.containerCount">
              <i class="icon-container material-table__icon"></i>
              {{ rowData.containerCount | number }}
            </div>
          </td>
          <ng-container *ngIf="showMountedCol">
            <ng-container *ngIf="isFlushingPrincipleManual(rowData); then mountedQuantity; else emptyQuantity"></ng-container>
          </ng-container>
        </ng-container>
        <ng-template #batchInfo>
          <ng-container *ngIf="!isToolType(rowData) && !isFlushingPrincipleManual(rowData)">
            <td class="material-table__tiny-cell"></td>
            <td [colSpan]="1" class="material-table__text-cell material-table__less-important-text">
              {{ rowData.batchInternalTitle | translate }}
              <br />
              {{ rowData.internalBatchId }}
            </td>
            <td [colSpan]="1" class="material-table__text-cell material-table__less-important-text">
              {{ rowData.batchExternalTitle | translate }}
              <br />
              {{ rowData.externalBatchId }}
            </td>
            <ng-container *ngIf="isAlternativeAvailable(rowNode.parent.data); then alternative; else emptyAlternative"></ng-container>
            <td *ngIf="showMountedCol" class="material-table__default-cell">‒</td>
          </ng-container>
          <ng-container *ngIf="isFlushingPrincipleManual(rowData) && !isToolType(rowData)">
            <td class="material-table__tiny-cell"></td>
            <td [colSpan]="1" class="material-table__text-cell material-table__less-important-text">
              {{ rowData.ssccCodeTitle | translate }}
              <br />
              <span [innerHTML]="rowData.ssccCode | sscc"></span>
            </td>
            <td [colSpan]="1" class="material-table__text-cell material-table__less-important-text">
              {{ rowData.batchInternalTitle | translate }} {{ rowData.internalBatchId }}
            </td>
            <ng-container *ngIf="isAlternativeAvailable(rowNode.parent.data); then alternative; else emptyAlternative"></ng-container>
            <td class="material-table__default-cell">
              {{ rowData.mountedQuantity | formatQuantity }}
            </td>
          </ng-container>
          <ng-container *ngIf="isToolType(rowData)">
            <td class="material-table__tiny-cell"></td>
            <td class="material-table__text-cell">{{ 'MOUNTING.SERIAL_NUMBER' | translate }}</td>
            <td [colSpan]="2" class="material-table__text-cell material-table__less-important-text">{{ rowData.externalSerialId }}</td>
            <td class="material-table__text-cell"></td>
            <td *ngIf="showMountedCol" class="material-table__default-cell">‒</td>
          </ng-container>
        </ng-template>

        <ng-template #emptyQuantity>
          <td class="material-table__default-cell">‒</td>
        </ng-template>
        <ng-template #mountedQuantity>
          <td class="material-table__default-cell">
            <div>{{ calculateMountedQuantity(rowData) | formatQuantity }}</div>
          </td>
        </ng-template>

        <ng-template #emptyAlternative>
          <td [colSpan]="2" class="material-table__default-cell">‒</td>
        </ng-template>
        <ng-template #alternative>
          <td [colSpan]="2" class="material-table__text-cell">
            {{ rowData.article.articleName }}
            <br />
            {{ rowData.article.externalArticleId }}
            <br />
            {{ rowData.article.externalConfigurationId }}
          </td>
        </ng-template>

        <ng-container *ngIf="!isToolType(rowData)">
          <td class="material-table__default-cell">{{ rowData.consumedQuantity | formatQuantity }}</td>
          <td class="material-table__default-cell">{{ rowData.openQuantity | formatQuantity }}</td>
          <td class="material-table__default-cell">{{ rowData.onHandQuantity | formatQuantity }}</td>
        </ng-container>
        <ng-container *ngIf="isToolType(rowData)">
          <td class="material-table__default-cell">‒</td>
          <td class="material-table__default-cell">‒</td>
          <td class="material-table__default-cell">{{ rowData.onHandQuantity | formatQuantity }}</td>
        </ng-container>
        <td *ngIf="buttonTemplate" class="material-table__last-cell" [style]="buttonTemplateStyle">
          <ng-container *ngTemplateOutlet="buttonTemplate; context: { $implicit: rowData }"></ng-container>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</section>
