import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiStateQuery, UiStateService } from '@app/core/global-state';
import { DismountContainerModalComponent } from '@app/modules/finish-phase/containers/overview/components/dismount-container-modal';
import { MountingCardViewOptions } from '@app/modules/mounting/models';
import { MountingService, MountPalletItemService } from '@app/modules/mounting/services';
import { PrimaryMountedMaterialQuery, RecentlyConsumedMaterialQuery, RecentlyConsumedMaterialService } from '@app/modules/mounting/state';
import { TranslateService } from '@ngx-translate/core';
import { ArticleDescription, PrimaryMaterial, PrimaryMaterialBlock, PrimaryMaterialMounted } from 'chronos-core-client';
import { AppSettingsQuery, DismountingPalletInformationMapper, Dummy, DummyMapper, MountedMaterial } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import { concat } from 'ramda';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { DummyPalletCreatingFormComponent } from '../dummy-pallet-creating-form/dummy-pallet-creating-form.component';
import { DismountDummyPalletModalComponent } from '@app/shared/components/dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';
import { DismountingPalletInformation } from 'chronos-shared';
import { ModalMainMaterialComponent } from '@app/shared/components/modal-main-material/modal-main-material.component';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Component({
  selector: 'app-container-material-view',
  templateUrl: './container-material-view.component.html',
  styleUrls: ['./container-material-view.component.scss']
})
export class ContainerMaterialViewComponent implements OnInit, OnDestroy {
  private readonly MAX_VISIBLE_CONTAINERS = 7;

  public mountedMaterials: MountedMaterial[];
  public activeMaterial: ArticleDescription;
  public activeMaterialBlock: PrimaryMaterialBlock;
  public nextMaterial$: Observable<ArticleDescription>;
  public plannedStartTime$: Observable<Date>;
  public optionsMountingView = [
    { label: 'MOUNTING.SWITCH_TO_CARD_VIEW', value: MountingCardViewOptions.Card },
    { label: 'MOUNTING.SWITCH_TO_LIST_VIEW', value: MountingCardViewOptions.List }
  ];
  public selectedMountingView: MountingCardViewOptions;
  public mountingCardViewOptions = MountingCardViewOptions;
  public machineState$ = this.mountingService.getMachineStateNotifications();
  public externalWorkCenterId: string;

  private subscriptions = new Subscription();

  constructor(
    private appSettingsQuery: AppSettingsQuery,
    private mountingService: MountingService,
    private consumedMaterialService: RecentlyConsumedMaterialService,
    private consumedMaterialQuery: RecentlyConsumedMaterialQuery,
    private primaryMountedMaterialQuery: PrimaryMountedMaterialQuery,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private mountPalletItemService: MountPalletItemService,
    private uiStateService: UiStateService,
    private uiStateQuery: UiStateQuery
  ) {}

  public ngOnInit(): void {
    this.externalWorkCenterId = this.appSettingsQuery.getExternalWorkCenterId();
    this.subscriptions.add(
      combineLatest([
        this.consumedMaterialQuery.consumedMaterial$.pipe(
          map((consumedMaterial) =>
            consumedMaterial.map((consumedContainer) => ({ ...consumedContainer, containerType: 'container' } as MountedMaterial))
          )
        ),
        this.primaryMountedMaterialQuery.primaryMountedMaterial$.pipe(map((mountedMaterial) => this.getMountedContainers(mountedMaterial)))
      ]).subscribe(([consumedMaterial, primaryMountedMaterial]) => {
        this.mountedMaterials = concat(consumedMaterial, primaryMountedMaterial) as MountedMaterial[];
      })
    );

    this.subscriptions.add(
      this.primaryMountedMaterialQuery.getActiveMaterialBlock().subscribe((materialBlock) => {
        this.activeMaterialBlock = materialBlock;
        this.activeMaterial = materialBlock?.article;
      })
    );

    this.nextMaterial$ = this.primaryMountedMaterialQuery.getNextMaterialArticle();
    this.plannedStartTime$ = this.primaryMountedMaterialQuery.getPlannedStartTime();

    this.plannedStartTime$ = this.primaryMountedMaterialQuery.getPlannedStartTime();

    this.selectedMountingView = this.uiStateQuery.getMountingPrimaryListView();
    this.registerPalletListActionResolvers();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getMountedContainers(primaryMountedMaterials: PrimaryMaterial): PrimaryMaterialMounted[] {
    let viewContainers = [];

    // the first material is the active one, the second is the next block. The display should show only the active materials,
    // therefore we extract just the first entry
    primaryMountedMaterials.materialBlocks.slice(0, 1).forEach(({ mountedMaterials, id }) => {
      const mountedMaterialsWithArticles = mountedMaterials.map((mountedContainer) => ({
        ...mountedContainer,
        materialBlockId: id,
        containerType: 'container'
      }));

      viewContainers = viewContainers.concat(mountedMaterialsWithArticles);
      viewContainers.push({ containerType: 'info' } as MountedMaterial);
    });

    while (viewContainers.length < this.MAX_VISIBLE_CONTAINERS) {
      viewContainers.push({ containerType: 'placeholder' } as MountedMaterial);
    }

    return viewContainers;
  }

  public changeMountingView(): void {
    this.uiStateService.setMountingPrimaryListview(this.selectedMountingView);
  }

  public openDummyPalletCreatingModal(articleId: number): void {
    this.dialogService.open(DummyPalletCreatingFormComponent, {
      data: { dummyPallet: { articleId, materialBlockId: this.activeMaterialBlock.id, bomUnitId: this.activeMaterial.bomUnitId } as Dummy },
      header: this.translateService.instant('MOUNTING.CREATE_DUMMY_PALLET')
    });
  }

  public onDismount(mountedMaterial: PrimaryMaterialMounted): void {
    if (mountedMaterial.container.isVirtualContainer) {
      this.dismountDummyPallet(mountedMaterial);
    } else {
      this.openPalletDismountingModal(mountedMaterial);
    }
  }

  public dismountDummyPallet(mountedMaterial: PrimaryMaterialMounted): void {
    this.dialogService.open(DismountDummyPalletModalComponent, {
      header: this.translateService.instant('DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT_DUMMY_PALLET'),
      data: {
        containerId: mountedMaterial.container.containerId,
        dismountingPalletInformation: {
          ssccCode: mountedMaterial.ssccCode,
          mounted: mountedMaterial.mountedQuantity,
          article: mountedMaterial.article,
          virtualContainerInfo: mountedMaterial.virtualContainerInfo,
          mountedMaterialId: mountedMaterial.mountedMaterialId,
          reason: mountedMaterial.container.virtualContainerReason,
          runId: mountedMaterial.container.runId
        } as DismountingPalletInformation
      }
    });
  }

  public openPalletDismountingModal(mountedMaterial: PrimaryMaterialMounted): void {
    this.dialogService
      .open(DismountContainerModalComponent, {
        header: this.translateService.instant('MOUNTING.DISMOUNTING'),
        data: {
          containerId: mountedMaterial.container.containerId,
          articleId: this.activeMaterial.id,
          dismountingPalletInformation: DismountingPalletInformationMapper.mapFrom(mountedMaterial, this.activeMaterial)
        }
      })
      .onClose.pipe(
        filter((isSubmit) => !!isSubmit),
        tap(() => {
          this.mountPalletItemService.onEndAnimation(mountedMaterial.mountedMaterialId);
        })
      )
      .subscribe();
  }

  public onInfoButtonClicked(primaryMaterialMounted: PrimaryMaterialMounted): void {
    const mountedMaterial = { ...primaryMaterialMounted } as MountedMaterial;
    this.dialogService.open(DummyPalletCreatingFormComponent, {
      data: {
        dummyPallet: DummyMapper.fromMountedMaterial(mountedMaterial),
        workCenterId: mountedMaterial.mountedWorkCenterId
      },
      header: this.translateService.instant('MOUNTING.CHANGE_DUMMY_PALLET')
    });
  }

  public onReplaceClick([mountedMaterialId, dummyContainerId]: [number, number]): void {
    const tabName = 'Primary';
    const submitLabel = 'MOUNTING.MOUNT';
    this.dialogService.open(ModalMainMaterialComponent, {
      header: this.translateService.instant('MOUNTING.MACHINE_LOCATION_MODAL', {
        workCenter: this.appSettingsQuery.getWorkCenterName()
      }),
      data: {
        tabName,
        mountedMaterialId,
        dummyContainerId,
        submitLabel
      }
    });
  }

  public onPalletTimeout(consumedMaterialId: number): void {
    this.consumedMaterialService.removePallet(consumedMaterialId);
  }

  public onWhiteWasteAdd([mountedMaterialId, whiteWaste]: [number, Quantity]): void {
    this.mountingService.addContainerWhiteWaste(mountedMaterialId, whiteWaste).subscribe(() => {
      whiteWaste.value = 0;
    });
  }

  public onWhiteWasteReset(mountedMaterialId: number): void {
    this.mountingService.removeContainerWhiteWaste(mountedMaterialId).subscribe();
  }

  private registerPalletListActionResolvers(): void {
    this.subscriptions.add(
      this.mountPalletItemService.infoButtonClick$.subscribe((mountedMaterial) => {
        this.onInfoButtonClicked(mountedMaterial);
      })
    );
    this.subscriptions.add(
      this.mountPalletItemService.replaceClick$.subscribe((dummyContainerInfo) => {
        this.onReplaceClick(dummyContainerInfo);
      })
    );
    this.subscriptions.add(
      this.mountPalletItemService.dismountClick$.subscribe((mountedMaterial) => {
        this.onDismount(mountedMaterial);
      })
    );
    this.subscriptions.add(
      this.mountPalletItemService.timeoutEnd$.subscribe((consumedMaterialId) => {
        this.onPalletTimeout(consumedMaterialId);
      })
    );
    this.subscriptions.add(
      this.mountPalletItemService.whiteWasteAdd$.subscribe((whiteWasteInfo) => {
        this.onWhiteWasteAdd(whiteWasteInfo);
      })
    );
    this.subscriptions.add(
      this.mountPalletItemService.whiteWasteReset$.subscribe((mountedMaterialId) => {
        this.onWhiteWasteReset(mountedMaterialId);
      })
    );
  }
}
