<div class="mount-pallet-list">
  <div class="mount-pallet-list__pallet-info">
    <lib-pallet-number
      class="mount-pallet-list__kpi"
      [palletNumber]="consumedContainer.materialSequence"
      [classification]="consumedContainer.article.classification"
    ></lib-pallet-number>
    <lib-pallet-kpi
      class="mount-pallet-list__kpi"
      [compact]="true"
      [kpiLabel]="'MOUNTING.SSCC' | translate"
      [kpiValue]="consumedContainer.ssccCode"
      [isSsccCodeShort]="true"
    ></lib-pallet-kpi>
  </div>
  <div class="mount-pallet-list__actions">
    <div class="waste-actions">
      <span>{{ consumedContainer.consumedQuantity | formatQuantity: false }}</span>
      <span class="waste-actions__unit">{{ consumedContainer.consumedQuantity.unitId }}</span>
    </div>
    <div class="waste-actions">
      <span>{{ consumedContainer.whiteWasteQuantity | formatQuantity: false }}</span>
      <span class="waste-actions__unit">{{ consumedContainer.whiteWasteQuantity.unitId }}</span>
    </div>
  </div>

  <div class="mount-pallet-list__article">
    <div class="article-action">
      <div>
        {{ consumedContainer.article?.externalArticleId }}
      </div>
      <div>
        {{ consumedContainer.article?.externalConfigurationId }}
      </div>
    </div>
  </div>

  <div class="mount-pallet-list__other-actions">
    <i class="icon-unmount-pallet input-icon input-icon--dismount" (click)="onDismountClick(consumedContainer)"></i>
  </div>
</div>
