<section class="registration-table">
  <p-table [value]="availableEmployees">
    <ng-template pTemplate="header">
      <tr>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.NAME' | translate }}</th>
        <th class="registration-table__default-cell">{{ 'EMPLOYEEREGISTRATION.ID' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.ACTIVE' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.ROLE' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.WORKCENTER' | translate }}</th>
        <th class="registration-table__text-cell">{{ 'EMPLOYEEREGISTRATION.LOGINTIME' | translate }}</th>
        <th class="registration-table__default-cell">{{ 'EMPLOYEEREGISTRATION.NOTE' | translate }}</th>
        <th class="registration-table__last-cell"></th>
        <!-- <th class="action-cell"></th> -->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="registration-table__text-cell">
          {{ rowData.name }}
        </td>
        <td class="registration-table__text-cell">
          {{ rowData.externalEmployeeId }}
        </td>
        <td class="registration-table__text-cell">
          <i *ngIf="rowData.isActive; else noData" class="icon icon-checked-solid"></i>
        </td>
        <td class="registration-table__text-cell">
          <span *ngIf="rowData.role; else noData">
            {{ 'EMPLOYEE_ROLE.' + rowData.role | uppercase | translate }}
          </span>
        </td>
        <td class="registration-table__text-cell">
          <span *ngIf="rowData.loggedOnExternalWorkCenterId; else noData">{{ rowData.loggedOnExternalWorkCenterId }}</span>
        </td>
        <td class="registration-table__text-cell">
          {{ rowData.loginTime | utcToLocal | date: 'dd.MM.YYYY HH:mm' }}
        </td>
        <td class="registration-table__text-cell">
          <span *ngIf="rowData.note; else noData">{{ rowData.note }}</span>
        </td>
        <td>
          <lib-button
            (clicked)="onLogInClick(rowData)"
            [label]="'EMPLOYEEREGISTRATION.LOGIN' | translate"
            [typeClass]="'button--primary'"
            [sizeClass]="'button--small'"
          ></lib-button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template #noData>
    <span>-</span>
  </ng-template>
</section>
