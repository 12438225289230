import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { UiStateService } from '@app/core/global-state';
import { nav } from '@app/shared/utils';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-operation-support',
  templateUrl: './operation-support.component.html',
  styleUrls: ['./operation-support.component.scss']
})
export class OperationSupportComponent implements OnInit {
  public operationSupportTabs: MenuItem[];
  public activeTab: MenuItem;

  private subscriptions = new Subscription();

  constructor(private router: Router, private uiStateService: UiStateService) {}

  public ngOnInit(): void {
    this.operationSupportTabs = [
      {
        label: 'TRACEABILITY.TITLE',
        routerLink: nav.routes.traceability
      }
    ];

    this.subscriptions.add(
      this.router.events.subscribe((event: RouterEvent) => {
        this.initStateRoute(event);
      })
    );
    this.activeTab = this.getActiveTab(this.operationSupportTabs);
  }

  private initStateRoute(event: RouterEvent) {
    const documentTab = this.operationSupportTabs.find((tab) => tab.routerLink === event.url);

    if (documentTab) {
      this.uiStateService.setDocumentStateSelectedRoute(event.url);
    }
  }

  private getActiveTab(tabs: MenuItem[]): MenuItem {
    return tabs.find((tab) => this.router.isActive(tab.routerLink, true));
  }
}
