<section class="material-table">
  <p-treeTable [value]="traceMaterialData" (onNodeExpand)="onNodeExpand($event)" (onNodeCollapse)="onNodeCollapse($event)">
    <ng-template pTemplate="header">
      <tr>
        <th class="material-table__toggle-cell">
          <i
            class="material-table__expand-collapse-icon"
            [ngClass]="tableExpanded ? 'icon-collapse-all' : 'icon-expand-all'"
            (click)="expandCollapseTable()"
          ></i>
        </th>
        <th class="material-table__text-cell">{{ 'MOUNTING.ARTICLE' | translate }}</th>
        <th [colSpan]="2" class="material-table__text-cell">{{ 'MOUNTING.NAME' | translate }}</th>
        <th class="material-table__text-cell"></th>
        <th class="material-table__text-cell"></th>
        <th class="material-table__text-cell"></th>
        <th class="material-table__default-cell">{{ 'MOUNTING.QUANTITY' | translate }}</th>
        <th class="material-table__default-cell">{{ 'MOUNTING.ASSIGNED' | translate }}</th>
        <th *ngIf="buttonTemplate"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr [ngClass]="rowData.isBatch ? 'second-level-row' : 'first-level-row'">
        <td class="material-table__toggle-cell">
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        </td>
        <ng-container *ngIf="!rowData.isBatch; else batchInfo">
          <td class="material-table__text-cell">{{ rowData.article.externalArticleId }}</td>
          <td [colSpan]="2" class="material-table__text-cell">{{ rowData.article.articleName }}</td>
          <td class="material-table__text-cell"></td>
          <td class="material-table__text-cell"></td>
          <td class="material-table__default-cell"></td>
          <td class="material-table__default-cell">
            <ng-container *ngIf="rowData.containerCount">
              <i class="assigned-icon icon-container"></i>
              {{ rowData.containerCount }}
            </ng-container>
          </td>
          <td class="material-table__default-cell">
            <i *ngIf="rowData.containerCount" class="assigned-icon icon-checked-solid"></i>
          </td>
          <td class="material-table__last-cell" *ngIf="buttonTemplate">
            <ng-container *ngTemplateOutlet="buttonTemplate; context: { $implicit: rowData }"></ng-container>
          </td>
        </ng-container>
        <ng-template #batchInfo>
          <td [colSpan]="1" class="material-table__text-cell material-table__less-important-text">
            {{ rowData.batchInternalTitle | translate }}
            <br />
            {{ rowData.internalBatchId }}
          </td>
          <td [colSpan]="1" class="material-table__text-cell material-table__less-important-text">
            {{ rowData.batchExternalTitle | translate }}
            <br />
            {{ rowData.externalBatchId }}
          </td>
          <ng-container *ngIf="isAlternativeAvailable(rowNode.parent.data); then alternative; else emptyAlternative"></ng-container>
          <td class="material-table__text-cell material-table__less-important-text">
            {{ rowData.infoTitle | translate }}
          </td>
          <td colspan="3" class="material-table__text-cell">
            {{ rowData.virtualContainerInfo ? rowData.virtualContainerInfo : ' - ' }}
          </td>
          <td class="material-table__last-cell" *ngIf="buttonTemplate">
            <ng-container *ngTemplateOutlet="buttonTemplate; context: { $implicit: rowData }"></ng-container>
          </td>
        </ng-template>

        <ng-template #emptyAlternative>
          <td [colSpan]="2" class="material-table__default-cell">‒</td>
        </ng-template>
        <ng-template #alternative>
          <td [colSpan]="2" class="material-table__text-cell">
            {{ rowNode.parent.data.alternativeArticles[0].articleName }}
            <br />
            {{ rowNode.parent.data.alternativeArticles[0].externalArticleId }}
            <br />
            {{ rowNode.parent.data.alternativeArticles[0].externalConfigurationId }}
          </td>
        </ng-template>
      </tr>
    </ng-template>
  </p-treeTable>
</section>
