import { Component, Input, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QaCheckField } from '@app/shared/models';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-qa-check-element',
  templateUrl: './qa-check-element.component.html',
  styleUrls: ['./qa-check-element.component.scss']
})
export class QaCheckElementComponent implements AfterViewInit {
  @Input() public parentForm: UntypedFormGroup;
  @Input() public field: QaCheckField;
  @Input() public isReadOnly?: false;

  public isCollapsed = true;
  public minFractionDigits: number = null;
  public maxFractionDigits: number = null;
  public readonly INPUT_STYLE = { width: '8em', textAlign: 'end' };
  public toggleContent(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public ngAfterViewInit() {
    this.setDecimalInput();
  }

  public isMeasurementUnitVisible(): boolean {
    return !this.field.isLineClearanceCheck && !!this.field.measurementResultUnitId && this.field.measurementResultUnitId.length > 0;
  }

  private setDecimalInput() {
    if (this.parentForm && this.field.measurementResultUnitId) {
      this.minFractionDigits = 2;
      this.maxFractionDigits = 10;
    }
  }
}
