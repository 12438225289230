import {
  Component,
  ViewContainerRef,
  Injector,
  OnInit,
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { HierarchyComponent } from './hierarchy-header.component';
import { SettingHierarchyViewModel } from 'chronos-core-client';
import { HierarchySetting } from '@app/modules/resource-configuration/models';
import { ResourceConfigurationService } from '@app/modules/resource-configuration/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-resource-header',
  templateUrl: './resource-header.component.html',
  styleUrls: ['./resource-header.component.scss']
})
export class ResourceHeaderComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges  {
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) public dynamicContainer: ViewContainerRef;
  @Output() public workCenterChange = new EventEmitter<{ level: number; entity: HierarchySetting; tenantEntity: HierarchySetting }>();
  @Input() public isDisabled: boolean;

  public dynamicComponents: HierarchyComponent[] = [];
  private hirarchySetting: HierarchySetting[] = [];
  private containerRefs: ViewContainerRef[] = [];
  private delDynamicComponents: HierarchyComponent[] = [];
  private subscriptions = new Subscription();
  private readonly DEFAULT_HIERARCHY_TYPE = 'WorkCenter';

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private resourceConfigurationService: ResourceConfigurationService
  ) {}

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    this.initSettingHierarchy();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.isDisabled && changes.isDisabled.currentValue !== changes.isDisabled.previousValue){
        this.dynamicComponents.forEach((item) => {
          item.isDisabled = this.isDisabled;
        });
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initSettingHierarchy(): void {
    this.subscriptions.add(
      this.resourceConfigurationService.getSettingHierarchy(this.DEFAULT_HIERARCHY_TYPE).subscribe((settingHierarchy) => {
        this.buildHierarchyDictionary(settingHierarchy, '');
        const entity = this.hirarchySetting[0];
        entity.isParentObjectClick = false;
        this.createDynamicComponent(0, entity);
      })
    );
  }

  private createDynamicComponent(level: number, entity: HierarchySetting): ComponentRef<HierarchyComponent> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(HierarchyComponent);
    const componentRef = componentFactory.create(this.injector, [], null);
    const hirarchySetting = this.hirarchySetting.filter((x) => x.name === entity.name && x.type === entity.type);

    if (entity) {
      const parentKey = `${entity.type}-${entity.name}`;
      const hirarchySettingLevel = this.hirarchySetting.filter((x) => x.parentKey === parentKey);
      hirarchySettingLevel.forEach((row) => (row.isActive = false));
      componentRef.instance.hirarchyLevel = hirarchySettingLevel;
    }

    const obj = { ...hirarchySetting[0], isParentObjectClick: false, isActive: true };
    componentRef.instance.hirarchyLevel.splice(0, 0, obj);
    componentRef.instance.level = level;

    this.updateDynamicComponents(level, entity, componentRef);

    return componentRef;
  }

  private updateDynamicComponents(level: number, entity: HierarchySetting, componentRef: ComponentRef<HierarchyComponent>): void {
    this.dynamicComponents
      .filter((x) => x.level >= level)
      .sort((a, b) => (a.level < b.level ? 1 : -1))
      .forEach((item) => {
        const index = this.dynamicComponents.findIndex((x) => x.level === item.level);
        this.containerRefs[index].detach(index);
        this.delDynamicComponents.push(this.dynamicComponents[index]);
      });

    this.delDynamicComponentsFromUI();

    if (componentRef.instance.hirarchyLevel.length > 1) {
      this.dynamicComponents.push(componentRef.instance);
      this.dynamicContainer.insert(componentRef.hostView);
      this.containerRefs.push(this.dynamicContainer);
    }

    const tenantEntity = this.hirarchySetting[0];
    this.workCenterChange.emit({ level, entity, tenantEntity });

    this.subscriptions.add(
      componentRef.instance.workCenterChange.subscribe((data) => {
        if (data.entity.isParentObjectClick) {
          this.createDynamicComponent(data.level + 1, data.entity);
        } else {
          this.updateDynamicComponentsAfterClick(data);
        }
      })
    );
  }

  private updateDynamicComponentsAfterClick(data: { level: number; entity: HierarchySetting }): void {
    const setting: HierarchySetting = data.entity;
    this.dynamicComponents
      .filter((x) => x.level > data.level)
      .sort((a, b) => (a.level < b.level ? 1 : -1))
      .forEach((item) => {
        const index = this.dynamicComponents.findIndex((x) => x.level === item.level);
        this.containerRefs[index].detach(index);
        this.delDynamicComponents.push(this.dynamicComponents[index]);
      });

    this.delDynamicComponentsFromUI();

    this.workCenterChange.emit({ level: data.level, entity: setting, tenantEntity : this.hirarchySetting[0] });
  }

  private buildHierarchyDictionary(entity: SettingHierarchyViewModel, parentKey: string): void {
    const key = this.getObject(entity, parentKey);
    this.hirarchySetting.push(key);
    parentKey = `${entity.hierarchyLevel}-${entity.entityId}`;
    for (const childEntity of entity.hierarchyLevelEntities) {
      this.buildHierarchyDictionary(childEntity, parentKey);
    }
  }

  private getObject(entity: SettingHierarchyViewModel, parentKey?: string): HierarchySetting {
    return {
      type: entity.hierarchyLevel,
      name: entity.entityId,
      parentKey,
      isParentObjectClick: true,
      isActive: false
    };
  }

  private delDynamicComponentsFromUI(): void {
    this.delDynamicComponents.forEach((item) => {
      const index = this.dynamicComponents.findIndex((x) => x.level === item.level);
      this.containerRefs.splice(index, 1);
      this.dynamicComponents.splice(index, 1);
    });

    this.delDynamicComponents = [];
  }
}
