<section class="primary-section">
  <div class="row justify-content-center">
    <p-selectButton
      class="primary-section__action"
      styleClass="chronos-select-button-dark"
      [options]="optionsMountingView"
      [(ngModel)]="selectedMountingView"
      (onChange)="changeMountingView()"
    >
      <ng-template let-item>
        <span *ngIf="item.icon" [ngClass]="item.icon"></span>
        <span *ngIf="item.label">{{ item.label | translate }}</span>
      </ng-template>
    </p-selectButton>
  </div>
</section>
<ng-container *ngIf="selectedMountingView === mountingCardViewOptions.List">
  <app-mount-pallet-list-template
    class="active-material-list"
    [activeMaterial]="activeMaterial"
    [nextMaterial]="nextMaterial$ | async"
    [plannedStartTime]="plannedStartTime$ | async"
    [externalWorkCenterId]="externalWorkCenterId"
    (newDummyPalletClicked)="openDummyPalletCreatingModal($event)"
  >
    <app-mount-pallet-list [mountedMaterials]="mountedMaterials" [machineState]="machineState$ | async"></app-mount-pallet-list>
  </app-mount-pallet-list-template>
</ng-container>
<ng-container *ngIf="selectedMountingView === mountingCardViewOptions.Card">
  <app-mount-pallet-cards
    [mountedMaterials]="mountedMaterials"
    [activeMaterial]="activeMaterial"
    [nextMaterial]="nextMaterial$ | async"
    [plannedStartTime]="plannedStartTime$ | async"
    [externalWorkCenterId]="externalWorkCenterId"
    [machineState]="machineState$ | async"
    (newDummyPalletClicked)="openDummyPalletCreatingModal($event)"
  ></app-mount-pallet-cards>
</ng-container>
