<div
  *ngIf="shiftReportItem"
  [ngSwitch]="status"
  class="order-item"
  [ngClass]="{ 'order-item--short': content === 'mainInfoOnly', 'order-item--not-started': status === STATUS.Open }"
>
  <ng-container *ngSwitchCase="STATUS.Downtime">
    <ng-container *let="shiftReportItem.downtime as downtime">
      <div *ngIf="content === 'full'" class="order-item__status-container">
        <lib-status-flag
          *let="TASK_STATUS_FLAGS['Downtime'] as downtimeFlag"
          class="order-item__status-label"
          [statusText]="downtimeFlag.text | translate"
          [styleClass]="downtimeFlag.labelClass"
          size="tiny"
        ></lib-status-flag>
      </div>
      <div class="order-item__order-container">{{ downtime.timeDiff | elapsedTime }}</div>
      <div
        class="info"
        [ngClass]="{ 'order-item__info-container-long-comment': isDowntime === true, 'order-item__info-container': isDowntime !== true }"
      >
        <div class="info__label">{{ 'SHIFT_REPORT.START_TIME' | translate }}</div>
        <div class="info__value">{{ shiftReportItem.startTime | utcToLocal | date: 'HH:mm' }}</div>
        <div class="info__label">{{ 'SHIFT_REPORT.REASON' | translate }}</div>
        <div class="info__value">{{ downtime.downtimeReasonLocalDescription }}</div>
        <div class="info__label"></div>
        <div class="info__value"></div>
        <div class="info__label"></div>
        <div class="info__value"></div>
        <div class="info__label">{{ 'SHIFT_REPORT.END_TIME' | translate }}</div>
        <div class="info__value">{{ shiftReportItem.endTime | utcToLocal | date: 'HH:mm' }}</div>
        <div class="info__label">{{ 'SHIFT_REPORT.REMARK' | translate }}</div>
        <div class="info__value" [ngClass]="{ info__maxwidth: isDowntime !== true, 'info__comment--maxwidth': isDowntime === true }">
          {{ downtime.info }}
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="STATUS.Open">
    <ng-container *let="shiftReportItem.productionOrder as order">
      <div *ngIf="content === 'full'" class="order-item__status-container">
        <lib-status-flag
          *let="PRODUCTION_JOB_STATUS_FLAGS['Open'] as openFlag"
          class="order-item__status-label"
          [statusText]="openFlag.text | translate"
          [styleClass]="openFlag.labelClass"
          size="tiny"
        ></lib-status-flag>
      </div>
      <div class="order-item__order-container">{{ order.code }}</div>
      <div class="order-item__info-container info">
        <div class="info__label">{{ 'SHIFT_REPORT.START_TIME' | translate }}</div>
        <div class="info__value">{{ shiftReportItem.startTime | utcToLocal | date: 'HH:mm' }}</div>
        <div class="info__label">{{ 'SHIFT_REPORT.GOOD_QUANTITY' | translate }}</div>
        <div
          *let="order.kpiValues | kpi: KPI_IDENTIFIER.GOOD_QUANTITY as goodQuantity"
          class="info__value"
          [title]="goodQuantity?.value?.value | number"
          [ngClass]="{ 'info__value--attention': goodQuantity?.hasViolation }"
        >
          {{ goodQuantity?.value | formatQuantity }}
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *let="shiftReportItem.productionOrder as order">
      <div *ngIf="content === 'full'" class="order-item__status-container">
        <lib-status-flag
          *let="PRODUCTION_JOB_STATUS_FLAGS[order.status] as statusFlag"
          class="order-item__status-label"
          [statusText]="statusFlag.text | translate"
          [styleClass]="statusFlag.labelClass"
          size="tiny"
        ></lib-status-flag>
        <i class="icon" [ngClass]="violationIcon"></i>
      </div>
      <div class="order-item__order-container">{{ order.code }}</div>
      <div class="order-item__info-container info">
        <div class="info__label">{{ 'SHIFT_REPORT.START_TIME' | translate }}</div>
        <div class="info__value">{{ shiftReportItem.startTime | utcToLocal | date: 'HH:mm' }}</div>
        <div class="info__label">{{ 'SHIFT_REPORT.GOOD_QUANTITY' | translate }}</div>
        <div
          *let="order.kpiValues | kpi: KPI_IDENTIFIER.GOOD_QUANTITY as goodQuantity"
          class="info__value"
          [title]="goodQuantity?.value?.value | number"
          [ngClass]="{ 'info__value--attention': goodQuantity?.hasViolation }"
        >
          {{ goodQuantity?.value | formatQuantity }}
        </div>
        <div class="info__label">{{ 'SHIFT_REPORT.KPI_IDENTIFIER_SETUP_TIME' | translate }}</div>
        <div
          *let="order.kpiValues | kpi: KPI_IDENTIFIER.SETUP_TIME as setupTime"
          class="info__value--important"
          [ngClass]="{ 'info__value--attention': setupTime?.hasViolation }"
        >
          {{ setupTime?.valueTime | elapsedTime }}
        </div>
        <div class="info__label">{{ 'SHIFT_REPORT.KPI_IDENTIFIER_OUTPUT_1' | translate }}</div>
        <div
          *let="order.kpiValues | kpi: KPI_IDENTIFIER.OUTPUT_1 as outputOne"
          class="info__value--important"
          [ngClass]="{ 'info__value--attention': outputOne?.hasViolation }"
        >
          {{ outputOne?.value | formatQuantity: false }}
        </div>
        <div class="info__label">{{ 'SHIFT_REPORT.END_TIME' | translate }}</div>
        <div class="info__value">{{ shiftReportItem.endTime | utcToLocal | date: 'HH:mm' }}</div>
        <div class="info__label">{{ 'SHIFT_REPORT.WASTE' | translate }}</div>
        <div
          *let="order.kpiValues | kpi: KPI_IDENTIFIER.WASTE as waste"
          class="info__value"
          [title]="waste?.value?.value | number"
          [ngClass]="{ 'info__value--attention': waste?.hasViolation }"
        >
          {{ waste?.value | formatQuantity }}
        </div>
        <div class="info__label">{{ 'SHIFT_REPORT.KPI_IDENTIFIER_RUN_TIME' | translate }}</div>
        <div
          *let="order.kpiValues | kpi: KPI_IDENTIFIER.RUN_TIME as runTime"
          class="info__value--important"
          [ngClass]="{ 'info__value--attention': runTime?.hasViolation }"
        >
          {{ runTime?.valueTime | elapsedTime }}
        </div>
        <div class="info__label">{{ 'SHIFT_REPORT.KPI_IDENTIFIER_OUTPUT_2' | translate }}</div>
        <div
          *let="order.kpiValues | kpi: KPI_IDENTIFIER.OUTPUT_2 as outputTwo"
          class="info__value--important"
          [ngClass]="{ 'info__value--attention': outputTwo?.hasViolation }"
        >
          {{ outputTwo?.value | formatQuantity: false }}
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="order-item__action-container">
    <ng-content></ng-content>
  </div>
</div>
