<section class="chronos-dialog--size-xs">
  <div class="quantity-change-modal">
    <form [formGroup]="modalForm" libEnterKeyHandler="submit_modal" class="quantity-change-modal__container">
      <label [for]="'change_quantity_input'" class="quantity-change-modal__container-label">
        {{ 'CHANGE_BOBBIN_LENGTH_DIALOG.BOBBIN_LENGTH' | translate }}
      </label>
      <lib-input-number inputId="change_quantity_input" [inputStyle]="INPUT_STYLE" formControlName="quantity"></lib-input-number>
      <span class="quantity-change-modal__container--unit">{{ unit }}</span>
    </form>
    <div *ngIf="isChangeFollowingBobbinsEnabled" class="quantity-change-modal__container">
      <p-checkbox
        [(ngModel)]="changeFollowingPallets"
        binary="true"
        [label]="'CHANGE_BOBBIN_LENGTH_DIALOG.CHANGE_LENGTH_FOLLOWING_CONTAINERS' | translate"
      ></p-checkbox>
    </div>
  </div>
  <lib-modal-footer
    (submitClicked)="submitModal()"
    (declineClicked)="closeModal()"
    [whenLoadingTopic]="LOADING_TOPIC"
    [submitDisabled]="modalForm.invalid"
  ></lib-modal-footer>
</section>
