<section class="chronos-dialog--size-xs">
  <div class="quantity-change-modal">
    <form [formGroup]="modalForm" libEnterKeyHandler="submit_modal" class="quantity-change-modal__container">
      <label [for]="'change_quantity_input'" class="quantity-change-modal__container-label">
        {{ articleClassification | titlecase }} {{ 'PALLET_CARD.QUANTITY' | translate }}
      </label>
      <lib-input-number inputId="change_quantity_input" [inputStyle]="INPUT_STYLE" formControlName="quantity"></lib-input-number>
      {{ quantity.unitId }}
    </form>
    <div *ngIf="isChangeFollowingPalletsEnabled" class="quantity-change-modal__container">
      <!-- <p-checkbox [(ngModel)]="changeFollowingPallets" binary="true" [label]="'PALLET_CARD.CHANGE_FOLLOWING' | translate : { articleClassification: articleClassification}"></p-checkbox> -->
      <p-checkbox [(ngModel)]="changeFollowingPallets" binary="true" [label]="'PALLET_CARD.CHANGE_FOLLOWING' | translate"></p-checkbox>
    </div>
  </div>
  <lib-modal-footer
    (submitClicked)="submitModal()"
    (declineClicked)="closeModal()"
    [whenLoadingTopic]="LOADING_TOPIC"
    [submitDisabled]="modalForm.invalid"
  ></lib-modal-footer>
</section>
