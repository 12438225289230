<lib-input-number
  [inputId]="inputId"
  [size]="5"
  [(ngModel)]="palletQuantity"
  [disabled]="disabled"
  (ngModelChange)="onQuantityChange($event)"
  (touched)="onTouched()"
  [required]="required"
  [inputStyle]="INPUT_STYLE"
></lib-input-number>
<span class="pallet-estimate">
  {{ totalOrderQuantity?.unitId }}
  <span *ngIf="palletQuantity">
    {{
      'SETUP_PARAMS.PALLET_ESTIMATE'
        | translate: { estimatedPallets: getPalletEstimation(), totalPallets: totalOrderQuantity | formatQuantity }
    }}
  </span>
</span>
