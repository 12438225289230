import { Injectable } from '@angular/core';
import { DowntimesService, EditDowntimeEntry, EditDowntimeStatus, RevertEditDowntimeEntry, SplitDowntimeEntry } from 'chronos-core-client';
import { Quantity } from 'projects/chronos-core-client/src/public-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DowntimeDsService {
  constructor(private downtimesService: DowntimesService) {}

  public splitDowntime(splitTime: string, downtimeId: number, workCenterId: number): Observable<null> {
    const editEntry: SplitDowntimeEntry = {
      splitTime,
      workCenterId
    };
    const param: DowntimesService.SplitDowntimeParams = {
      entry: editEntry,
      downtimeId
    };

    return this.downtimesService.splitDowntime(param);
  }

  // Get edit downtime status
  public getEditDowntimeStatus(downtimeId: number): Observable<EditDowntimeStatus> {
    return this.downtimesService.getEditDowntimeStatus(downtimeId);
  }

  // Update/Save downtime
  public editDowntime(downtimeId: number, workCenterId: number, startTime: string,
    startCounter: number, endTime: string, endCounter: number, wasteQuantity: Quantity): Observable<null> {
    const editEntry: EditDowntimeEntry = {
      workCenterId,
      startTime,
      startCounter,
      endTime,
      endCounter,
      wasteQuantity
    };

    const param: DowntimesService.EditDowntimeParams = {
      entry: editEntry,
      downtimeId
    };

    return this.downtimesService.editDowntime(param);
  }

  // Revert edit downtime
  public revertEditDowntime(downtimeId: number, workCenterId: number): Observable<null> {

    const revertEntry: RevertEditDowntimeEntry = {
      workCenterId
    };

    const param: DowntimesService.RevertEditDowntimeParams = {
      downtimeId,
      entry: revertEntry
    };

    return this.downtimesService.revertEditDowntime(param);
  }

}
