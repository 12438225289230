<ng-container *ngIf="collapsed; else other">
  <p-splitButton
    [disabled]="primaryButton?.disabled"
    [model]="optionButtons"
    [appendTo]="appendTo"
    styleClass="chronos-split-button chronos-split-button--toggle"
    menuStyleClass="chronos-split-button-menu"
  ></p-splitButton>
</ng-container>
<ng-template #other>
  <ng-container *ngIf="singleItem; else multi">
    <p-button
      [libLoadingTopic]="loadingTopic"
      [disabled]="primaryButton?.disabled || loading"
      [label]="primaryButton?.label"
      (onClick)="onButtonClick()"
      (loadingEvent)="loading = $event"
      styleClass="chronos-split-button-single"
    ></p-button>
  </ng-container>
  <ng-template #multi>
    <p-splitButton
      [libLoadingTopic]="loadingTopic"
      [disabled]="primaryButton?.disabled || loading"
      [label]="primaryButton?.label"
      [model]="optionButtons"
      [appendTo]="appendTo"
      (onClick)="onButtonClick()"
      (loadingEvent)="loading = $event"
      styleClass="chronos-split-button"
      menuStyleClass="chronos-split-button-menu"
    ></p-splitButton>
  </ng-template>
</ng-template>
