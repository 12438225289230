<app-time-edit-template class="order-start chronos-dialog--size-md">
  <app-time-edit-header>
    <div class="order-start__order-code">{{ orderItem?.externalProductionOrderId }}</div>
    <div class="order-start__open-time">{{ modalOpenTime | date: 'HH:mm' }}</div>
  </app-time-edit-header>
  <app-time-edit-header>{{ 'MANUAL_MODE.JOB_START_TITLE_LONG' | translate }}</app-time-edit-header>
  <app-time-edit-content class="content" [ngClass]="{ 'content--warning': !selectedStartTime }">
    <div>{{ 'MANUAL_MODE.START' | translate }}</div>
    <lib-calendar
      [(ngModel)]="selectedStartTime"
      [minDate]="minSelectableDate"
      [maxDate]="modalOpenTime"
      [inputStyle]="{ width: '270px' }"
    ></lib-calendar>
  </app-time-edit-content>
  <app-time-edit-content class="content" [ngClass]="{ 'content--warning': !quantityPerPallet, 'content--error': !isQuantityAcceptable() }">
    <div>{{ 'MANUAL_MODE.QUANTITY_PER_PALLET' | translate }}</div>
    <lib-input-number
      [inputStyle]="{ width: '250px' }"
      [(ngModel)]="quantityPerPallet"
      [minFractionDigits]="decimalDigitCount"
      [maxFractionDigits]="decimalDigitCount"
      [min]="0"
      [unit]="unit"
    ></lib-input-number>
  </app-time-edit-content>
  <lib-modal-footer
    [whenLoadingTopic]="LOADING_TOPIC"
    [submitDisabled]="!isModalValid()"
    (submitClicked)="startOrder()"
    (declineClicked)="closeModal()"
    [submitLabel]="'MANUAL_MODE.MODAL_OK'"
  ></lib-modal-footer>
</app-time-edit-template>
