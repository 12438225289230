<div [ngClass]="backgroundColorClass" class="upper-part">
  <label class="labelText">{{ valuePropertyBag.unit }}</label>
  <div class="value" [ngClass.xs]="{ value: false, 'value-mobile': true }" [ngClass.sm]="{ value: false, 'value-mobile': true }">
    {{ valuePropertyBag.value }}
  </div>
  <div class="kpi">
    <div class="kpi-label"></div>
    <div class="kpi-value ellipsis"></div>
  </div>
</div>

<div class="bottom-background-color lower-part">
  <div class="labelText">
    <span *ngIf="valuePropertyBag.targetValue">{{ 'SPEED_MONITOR.TARGET_SPEED' | translate }}</span>
    <span *ngIf="!valuePropertyBag.targetValue">{{ 'SPEED_MONITOR.MAX_MACHINE_SPEED' | translate }}</span>
  </div>
  <div
    class="target-value"
    [ngClass.xs]="{ 'target-value': false, 'target-value-mobile': true }"
    [ngClass.sm]="{ 'target-value': false, 'target-value-mobile': true }"
  >
    <span *ngIf="valuePropertyBag.targetValue">{{ valuePropertyBag.targetValue }}</span>
    <span *ngIf="!valuePropertyBag.targetValue">{{ valuePropertyBag.value }}</span>
  </div>
  <div *ngIf="currentKpi && currentKpi.name && valuePropertyBag.mode === 'FullScreen'" class="kpi">
    <div class="lower-kpi-label">
      {{ currentKpi.name }}
    </div>
    <div class="lower-kpi-value">
      <label>{{ currentKpi.value }}{{ currentKpi.valueTime }}</label>
    </div>
  </div>
</div>
