<p-table
  #dt
  [columns]="tableColumns"
  [value]="billOfMaterials"
  [globalFilterFields]="['flushingPrinciple']"
  [scrollable]="true"
  [scrollHeight]="scrollHeight"
  class="bill-of-material-table"
>
  <ng-template pTemplate="caption">
    <p-selectButton
      class="text-right"
      styleClass="chronos-select-button-blue"
      [options]="filters"
      [(ngModel)]="selectedFilter"
      (onChange)="globalDataFilter($event.value)"
    >
      <ng-template let-item>
        <span>{{ item.label | translate }}</span>
      </ng-template>
    </p-selectButton>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        [style.width]="col?.width"
        [style.textAlign]="col?.textAlign"
        class="bill-of-material-table__column"
        [ngClass]="col?.textAlign === 'right' ? 'text-right' : 'bill-of-material-table__column'"
      >
        {{ col.header | translate }}
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        {{ 'MOUNTING.NO_BOM_AVAILABLE' | translate }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td class="bill-of-material-table__column text-left" [ngSwitch]="rowData.materialType" [style.width]="colSizes.statusColSize">
        <i *ngSwitchCase="MATERIAL_TYPE.PRIMARY" class="icon icon--large icon-pallet-scanned"></i>
        <i
          *ngSwitchCase="MATERIAL_TYPE.SECONDARY"
          class="icon icon--large"
          [ngClass]="rowData.onhand.containerCount > 0 ? 'icon--success icon-container' : 'icon--neutral icon-container'"
        ></i>
        {{ rowData.onhand.containerCount }}
      </td>
      <ng-container *ngIf="showInactive">
        <td class="bill-of-material-table__column text-left">
          <i
            class="icon icon--large"
            [ngClass]="{
              'icon--warning icon-warning-solid': rowData.timeLeftIcon === TIME_LEFT_ICON.WARNING,
              'icon--error icon-error-solid': rowData.timeLeftIcon === TIME_LEFT_ICON.ERROR
            }"
          ></i>
          {{ rowData.timeLeft | elapsedTime }}
        </td>
      </ng-container>
      <td class="bill-of-material-table__column text-right" [style.width]="colSizes.articleColSize">
        <div class="align-horizontal">
          <i
            *ngIf="isAlternate(rowData.alternativeForBomId)"
            title="{{ getTooltip(rowData.alternativeForArticle) }}"
            class="icon icon-active-order"
          ></i>
          <div>{{ rowData.externalArticleId | emptyTextPlaceholder }}</div>
        </div>
        <div>{{ rowData.externalConfigurationId | emptyTextPlaceholder }}</div>
      </td>
      <td class="bill-of-material-table__column text-left" [style.width]="colSizes.nameColSize">
        <div>{{ rowData.articleName | emptyTextPlaceholder }}</div>
        <div>{{ rowData.configurationName | emptyTextPlaceholder }}</div>
      </td>
      <td class="bill-of-material-table__column text-right" [style.width]="colSizes.mountedColSize">
        <div>{{ rowData.mounted.quantity | formatQuantity }}</div>
        <div *ngIf="isPrimaryType(rowData.materialType)">{{ rowData.mounted.containerCount | number }} PAL</div>
      </td>
      <td class="bill-of-material-table__column text-right" [style.width]="colSizes.openColSize">
        <div>{{ rowData.open.quantity | formatQuantity }}</div>
      </td>
      <td class="bill-of-material-table__column text-right">
        <div>{{ rowData.onhand.quantity | formatQuantity }}</div>
        <div *ngIf="isPrimaryType(rowData.materialType)">
          {{ rowData.onhand.weightKg | formatQuantity }}
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
