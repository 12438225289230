import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpRequest } from '@angular/common/http';
import localeAt from '@angular/common/locales/en-AT';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WorkCenterSelectionModule } from '@app/modules/work-center-selection/work-center-selection.module';
import { ShellModule } from '@app/shell/shell.module';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { environment } from '@environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { webSocketAccessTokenFactory } from 'chronos-core-client';
import {
  ApiAuthorizationModule,
  AUTHORIZE_INTERCEPTOR_MATCHER,
  AUTHORIZE_LOGIN_HOOKS,
  AuthorizeAccessTokenFactory,
  AuthorizeInterceptor,
  AuthorizeService,
  ChronosSharedConfiguration,
  ChronosSharedModule,
  FormatQuantityPipe,
  HttpErrorInterceptor,
  sameOriginMatcher
} from 'chronos-shared';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MachineChartModule } from './modules/machine-chart/machine-chart.module';
import { WorkCenterInitService } from './work-center-init.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?cache-buster=${new Date().getTime()}`);
}

function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => appInitService.Init();
}

function initializeWorkCenterOnLogin(workCenterInitService: WorkCenterInitService) {
  return (): Promise<any> => workCenterInitService.initializeWorkCenter();
}

function createAuthInterceptorMatcher(sharedConfiguration: ChronosSharedConfiguration) {
  return (req: HttpRequest<any>) =>
    req.url.startsWith(sharedConfiguration.coreRootUrl) ||
    req.url.startsWith(sharedConfiguration.signalrBaseUrl) ||
    req.url.startsWith(sharedConfiguration.liveRootUrl);
}

registerLocaleData(localeAt);
registerLocaleData(localePl);

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApmModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: false
    }),
    ChronosSharedModule.forRoot({
      pandaRootUrl: environment.pandaApiUrl,
      coreRootUrl: environment.coreApiUrl,
      liveRootUrl: environment.liveApiUrl,
      signalrBaseUrl: environment.signalrBaseUrl
    }),
    ShellModule,
    WorkCenterSelectionModule,
    MachineChartModule,
    ApiAuthorizationModule.forRoot({
      authority: 'https://portal.chronos.tietoevry.com/authenticator',
      clientId: 'chronos-dev'
    })
  ],
  providers: [
    ApmService,
    { provide: ErrorHandler, useClass: ApmErrorHandler },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true },
    { provide: LOCALE_ID, useValue: 'en-AT' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: AUTHORIZE_INTERCEPTOR_MATCHER, useValue: sameOriginMatcher, multi: true },
    {
      provide: AUTHORIZE_INTERCEPTOR_MATCHER,
      useFactory: createAuthInterceptorMatcher,
      deps: [ChronosSharedConfiguration],
      multi: true
    },
    {
      provide: AUTHORIZE_LOGIN_HOOKS,
      useFactory: initializeWorkCenterOnLogin,
      deps: [WorkCenterInitService],
      multi: true
    },
    { provide: webSocketAccessTokenFactory, useClass: AuthorizeAccessTokenFactory, deps: [AuthorizeService] },
    FormatQuantityPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
