<section class="mounting page-padding">
  <p-tabMenu [model]="mountingTabs" [activeItem]="getActiveTab()">
    <ng-template pTemplate="item" let-item>
      <div class="ui-menuitem-text">
        {{ item.label | translate }}
      </div>
    </ng-template>
  </p-tabMenu>

  <div class="mounting__content">
    <router-outlet></router-outlet>
  </div>
</section>
