import { Component, OnInit } from '@angular/core';
import { TabTraceService } from '@app/modules/mounting/services/tab-trace/tab-trace.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ArticleDescription, CreateAndAssignTraceContainerEntry } from 'chronos-core-client';
import { notificationTopic } from '@app/shared/utils';
import { finalize } from 'rxjs/operators';
import { AppSettingsQuery, LoadingNotificationService } from 'chronos-shared';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-trace-material-assignation-modal',
  templateUrl: './trace-material-assignation-modal.component.html',
  styleUrls: ['./trace-material-assignation-modal.component.scss']
})
export class TraceMaterialAssignationModalComponent implements OnInit {
  public readonly LOADING_TOPIC: string = notificationTopic.modalTraceAssign;

  public modalForm: UntypedFormGroup;
  public article: ArticleDescription;
  public showInactive: boolean;

  constructor(
    private tabTraceService: TabTraceService,
    private appSettingsQuery: AppSettingsQuery,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  public ngOnInit(): void {
    this.showInactive = this.appSettingsQuery.showInactiveElement();
    this.article = this.config.data.article;
    this.modalForm = new UntypedFormGroup({
      internalBatchId: new UntypedFormControl('', [Validators.required]),
      externalBatchId: new UntypedFormControl(''),
      batchInfo: new UntypedFormControl('')
    });
  }

  public createAndAssignTraceMaterial(): void {
    if (this.modalForm.invalid || this.modalForm.disabled) {
      return;
    }

    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    const entry: CreateAndAssignTraceContainerEntry = {
      externalBatchId: this.modalForm.value.externalBatchId,
      info: this.modalForm.value.batchInfo,
      materialBlockId: this.config.data.materialBlockId,
      articleId: this.article.id,
      internalBatchId: this.modalForm.value.internalBatchId
    };

    this.tabTraceService
      .createAndAssignTraceMaterial(entry)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.closeAssignModal(true);
      });
  }

  public closeAssignModal(isSubmit: boolean): void {
    this.ref.close(isSubmit);
  }
}
