import { Quantity } from 'chronos-core-client';

export interface MaterialTableDataRow {
  externalArticleId: string;
  externalPalletId: string;
  ssccCode: string;
  internalBatchId: string;
  externalBatchId: string;
  externalSerialId: string;
  quantity: Quantity;
  warehouseName: string;
  warehouseLocationName: string;
  dateTimeAvailable: string;
  onHandWeight: Quantity;
}

export interface MaterialTableColumns {
  field: string;
  header: string;
  type?: 'quantity' | 'number' | 'datetime' | 'sscc' | 'status';
  style?: string;
  width?: number;
}

export enum TabNameEnum {
  Overview = 'Overview',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Trace = 'Trace',
  ToolSetup = 'ToolSetup'
}
