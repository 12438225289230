import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-edit-content',
  templateUrl: './time-edit-content.component.html',
  styleUrls: ['./time-edit-content.component.scss']
})
export class TimeEditContentComponent implements OnInit {
  constructor() {}

  public ngOnInit(): void {}
}
