<div
  class="mount-pallet"
  [ngClass]="{
    'mount-pallet--active': currentMountedMaterial.mountedMaterialStatus === materialStatuses.ACTIVE,
    'mount-pallet--dummy': currentMountedMaterial.container.isVirtualContainer
  }"
>
  <app-active-pallet-animation [isAnimationPlay]="animationState === ANIMATION_STATES.PLAYING"></app-active-pallet-animation>

  <div class="mount-pallet__header">
    <lib-pallet-number
      [palletNumber]="currentMountedMaterial.materialSequence"
      [classification]="currentMountedMaterial.article.classification"
    ></lib-pallet-number>
    <div class="mount-pallet__header-info">
      <div *ngIf="currentMountedMaterial.container.article.externalArticleId">
        {{ currentMountedMaterial.container.article.externalArticleId }}
      </div>
      <div>
        {{ currentMountedMaterial.container.article.externalConfigurationId }}
      </div>
    </div>
    <i
      (click)="onDismountClick(currentMountedMaterial)"
      class="mount-pallet__mount-status icon-unmount-pallet"
      [ngClass]="{ 'mount-pallet__mount-status--dismounting-allowed': isDismountingAllowed() }"
    ></i>
  </div>
  <div class="mount-pallet__info">
    <div *ngIf="currentMountedMaterial.qaCheckResult && this.showStatus" class="qa-check">
      <ng-container>
        <lib-quality-status
          (click)="openQACheckModalDialog()"
          [checkClass]="palletQualityStatus.labelClass"
          [checkText]="palletQualityStatus.text | translate"
        ></lib-quality-status>
      </ng-container>
    </div>
    <div>
      <lib-pallet-kpi
        [showInfoIcon]="currentMountedMaterial.container.isVirtualContainer"
        (infoButtonClicked)="onInfoButtonClick()"
        [kpiLabel]="'MOUNTING.SSCC' | translate"
        [kpiValue]="currentMountedMaterial.ssccCode"
        [isSsccCodeShort]="true"
      ></lib-pallet-kpi>
    </div>
    <lib-pallet-kpi
      [kpiLabel]="'MOUNTING.MOUNTED' | translate"
      [kpiValue]="currentMountedMaterial.mountedQuantity | formatQuantity: false"
      [emphasizeText]="true"
    ></lib-pallet-kpi>
  </div>

  <div class="mount-pallet__actions" *ngIf="currentMountedMaterial.container.isVirtualContainer">
    <div class="mount-pallet__actions-label">
      <i class="icon icon-warning-solid"></i>
      {{ 'MOUNTING.DUMMY' | translate }}
    </div>
    <div class="mount-pallet__actions-input">
      <lib-button
        class="mount-pallet__actions-input-button"
        [label]="'MOUNTING.REPLACE'"
        (clicked)="onReplaceClick(currentMountedMaterial.mountedMaterialId, currentMountedMaterial.container.containerId)"
      ></lib-button>
    </div>
  </div>

  <div *ngIf="!currentMountedMaterial.container.isVirtualContainer">
    <div class="mount-pallet__actions">
      <div class="mount-pallet__actions-label">
        {{ 'MOUNTING.WHITE_WASTE' | translate }}
      </div>
      <div class="mount-pallet__actions-input">
        <lib-input-number
          [inputStyle]="INPUT_STYLE"
          [(ngModel)]="whiteWaste.value"
          (focused)="stopAnimationOnFocus()"
          (touched)="startAnimationOnBlur()"
          [maxFractionDigits]="maxFractionDigits"
          [minFractionDigits]="minFractionDigits"
        ></lib-input-number>
        <lib-button
          [label]="'MOUNTING.OK'"
          class="mount-pallet__actions-input-button"
          (clicked)="addContainerWhiteWaste(currentMountedMaterial.mountedMaterialId)"
        ></lib-button>
      </div>
    </div>

    <div class="mount-pallet__actions">
      <div class="mount-pallet__actions-label">{{ 'MOUNTING.TOTAL' | translate }}</div>
      <div class="mount-pallet__actions-input">
        {{ currentMountedMaterial.whiteWasteQuantity | formatQuantity: false }}
        <i
          class="icon-reset mount-pallet__actions-input-icon"
          (click)="resetContainerWhiteWaste(currentMountedMaterial.mountedMaterialId)"
        ></i>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
