<lib-input-number
  [inputId]="inputId"
  [size]="5"
  [(ngModel)]="bobbinQuantity"
  [disabled]="disabled"
  (ngModelChange)="onBobbinQuantityChange($event)"
  (touched)="onBobbinTouched()"
  [required]="required"
  [inputStyle]="INPUT_STYLE"
></lib-input-number>
<span class="pallet-estimate">
  {{ unit }}
  <span *ngIf="bobbinQuantity">
    {{ 'SETUP_PARAMS.BOBBIN_ESTIMATE' | translate: { bobbinPerPallet: getBobbinPerPallet(), estimatedBobbins: getBobbinEstimation() } }}
  </span>
</span>
